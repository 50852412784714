.menu-item {
  .col-left {
    width: 12%;
    text-align: center;
  }
  .col-middle {
    width: 75%;
  }
  .col-right {
    width: 13%;
  }

  &.selected {
    background-color: $gray-200;
  }

  border-bottom: $gray-400 1px solid;
}
