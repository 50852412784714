hr.black {
  border-top-color: black;
  border-top-width: 0.15rem;
}

.letter-spacing-sm {
  letter-spacing: 0.1rem;
}

.letter-spacing {
  letter-spacing: 0.15rem;
}
