.fo-modal {
  &.fo-modal__overlay {
    position: fixed;
    z-index: 999999;
    top: 0;
    left: 0;
    width: 100vw;

    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);

    background: rgba(0, 0, 0, 0.3);

    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    padding-top: 3rem;
    @include media-breakpoint-down(sm) {
      padding-top: 0;
    }
  }

  .fo-modal__content {
    position: relative;
    width: 600px;
    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }

  .fo-modal__content_large {
    position: relative;
    width: 90vw;
    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }

  .fo-footer-button-area {
    background-color: rgba(248, 249, 250, 0.9);
    height: $ios-dead-zone-height * 2.5;
    position: sticky;
    bottom: 0;
  }

  .fo-modal__close-svg-icon {
    -webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.3));
    filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.3));
    height: 30px;
    width: 30px;
  }
}
