$progress-bar-border-radius: 5em;

.btn-faster-order {
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  text-align: center;
  padding: 1rem 0rem;
}

.btn-branded {
  opacity: 1;

  &:hover {
    opacity: 0.95;
    transition: 0.3s;
  }
}

.btn-create-list-item {
  border: 2px dashed lightgray;

  &:hover {
    opacity: 0.7;
  }
  &:focus {
    box-shadow: none;
  }
}
