$link-color: #000;

@media (hover: hover) {
  .link {
    color: $link-color;
    cursor: pointer;
  }

  .link:hover {
    opacity: 0.6;
  }
}

.add-link-style:hover {
  background-color: rgba(0, 0, 0, 0.05);
  cursor: pointer;
}
