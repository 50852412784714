.modal-overlay {
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  width: 100vw;

  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);

  background: rgba(0, 0, 0, 0.3);
  z-index: $zindex-modal-backdrop;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  padding-top: 3rem;
  @include media-breakpoint-down(sm) {
    padding-top: 0;
  }
}
