.image-hoverzoom {
  overflow: hidden;
  div {
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
  }
}

.image-hoverzoom:hover div,
.image-hoverzoom:focus div {
  transform: scale(1.2);
}
