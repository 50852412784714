$font-family-base: "Muli", sans-serif;
$font-size-base: 0.95rem !default;
$small-font-size: 75%;

$list-group-item-padding-y: 0.5em;

// colors
$primary: #0ca390;
$dark: #000;

// customize buttons
$border-radius: 0;
$border-radius-lg: 2rem;

$ios-dead-zone-height: 60px; // fix for ios deadzone, https://www.eventbrite.com/engineering/mobile-safari-why/
