.header {
  @include media-breakpoint-up(md) {
    height: 300px;
  }
  @include media-breakpoint-down(md) {
    height: 300px;
  }

  .header__close-button {
    position: absolute;
    right: 15px;
    top: 15px;
    color: rgba(255, 255, 255, 0.5);
  }
}
