/* Gilroy-Black */
@font-face {
  font-family: 'Gilroy';
  src: url('../fonts/Gilroy-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

/* Gilroy-ExtraBold */
@font-face {
  font-family: 'Gilroy';
  src: url('../fonts/Gilroy-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

/* Gilroy-Bold */
@font-face {
  font-family: 'Gilroy';
  src: url('../fonts/Gilroy-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

/* Gilroy-SemiBold */
@font-face {
  font-family: 'Gilroy';
  src: url('../fonts/Gilroy-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

/* Gilroy-Medium */
@font-face {
  font-family: 'Gilroy';
  src: url('../fonts/Gilroy-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

/* Gilroy-Regular */
@font-face {
  font-family: 'Gilroy';
  src: url('../fonts/Gilroy-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

/* Gilroy-Light */
@font-face {
  font-family: 'Gilroy';
  src: url('../fonts/Gilroy-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

/* Gilroy-Thin */
@font-face {
  font-family: 'Gilroy';
  src: url('../fonts/Gilroy-UltraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

/* Gilroy-UltraLight */
@font-face {
  font-family: 'Gilroy';
  src: url('../fonts/Gilroy-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}
