$wide-letter-spacing: 0.2em;

.text-grow-lineheight {
  line-height: 160%;
}

.text-wider {
  letter-spacing: $wide-letter-spacing;
}

.text-90 {
  font-size: 90%;
}

.text-75 {
  font-size: 75%;
}

// text tyle

.fo__field__title {
  letter-spacing: $wide-letter-spacing;
  font-size: $font-size-base * 0.95;
  font-weight: bold;
}
.fo__field__value {
  font-size: $h2-font-size;
  font-weight: bold;
}

.text-cursor {
  cursor: pointer;
}

.text-strike {
  text-decoration: line-through;
}
