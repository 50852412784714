.progress-bar {
  .progress-bar__el {
    padding: 3px;
    flex: 1;
    border-style: solid;
    border-width: 1px;
    border-left-style: none;
  }

  .progress-bar__el:first-child {
    border-left-style: solid;
    border-top-left-radius: $progress-bar-border-radius;
    border-bottom-left-radius: $progress-bar-border-radius;
  }
  .progress-bar__el:last-child {
    border-top-right-radius: $progress-bar-border-radius;
    border-bottom-right-radius: $progress-bar-border-radius;
  }
}
