body {
  background-color: $gray-100;
}

/** 
keep the style for the html and the body. 
it forces the navbar on ios to be visible and makes it easier to click on the 
bottom button.
*/
html,
body {
  -webkit-overflow-scrolling: touch !important;
}
