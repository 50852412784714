/*!
 * Start Bootstrap - Simple Sidebar (https://startbootstrap.com/template-overviews/simple-sidebar)
 * Copyright 2013-2019 Start Bootstrap
 * Licensed under MIT (https://github.com/BlackrockDigital/startbootstrap-simple-sidebar/blob/master/LICENSE)
 */

.sidebar-layout {
  .sidebar-layout__sidebar {
    width: 15rem;
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 0;

    @include media-breakpoint-down(md) {
      box-shadow: 0 15px 20px rgba(0, 0, 0, 0.3);
      z-index: $zindex-modal - 5;
    }
  }

  .sidebar-layout__content {
    @include media-breakpoint-up(md) {
      margin-left: 15rem;
      padding-top: 0;
    }
  }
}
