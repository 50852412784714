.form-check.large {
  input[type="radio"] {
    transform: scale(1.3);
  }
  margin-top: ($spacer * 0.5) !important;
  margin-bottom: ($spacer * 0.5) !important;
}

.form-check-label-padding {
  padding-left: 15px;
}
