.nav-tabs {
  overflow-x: scroll;
  overflow-y: hidden;
  flex-wrap: nowrap;

  .nav-item {
    color: #9f9f9d;
    .nav-link {
      color: #9f9f9d;
      white-space: nowrap;
    }

    .nav-link.active,
    .nav-link:active {
      background-color: transparent;
      border-color: transparent;
      color: black;
    }

    .nav-link:hover {
      border-color: transparent;
    }
  }
}
