.transaction-summary {
  .col1 {
    width: 10%;
    padding-right: 7px;
    @include media-breakpoint-down(xs) {
      width: 10%;
    }
  }
  .col3 {
    width: 15%;
    padding-left: 7px;
    @include media-breakpoint-down(xs) {
      width: 22%;
    }
  }
}
