.modal-top__cartitem__with-image {
  // background-image: url(https://upload.wikimedia.org/wikipedia/commons/c/c8/Pizza_Margherita_stu_spivack.jpg);
  background-size: cover;
  height: 200px;
  background-repeat: no-repeat;

  @media only screen and (min-width: 600px) {
    height: 400px;
  }
}
